import React, { Suspense, useState, useEffect, useRef } from 'react';
import './App.css'; // Import your CSS file for the modal styles

const UserLevelStatusLazy = React.lazy(() => import('./userLevelStatus'));

const GamingModal = () => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const [indexLanguage, setIndexLanguage] = useState('');

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []); // Empty dependency array ensures this runs once on mount

  const handleClickOutside = (event: MouseEvent) => {
    // Close modal if clicking outside
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  // Add click event listener to close modal when clicked outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  return (
    <div>
      <span onClick={() => setShowModal(true)} className="button-search">
        <i className="fa-solid fa-shield"></i>
      </span>

      {showModal && (
        <div className="aroundModal">
          <div className="modal-container-game" ref={modalRef}>
            <button 
              className="close-button-modal" 
              onClick={() => setShowModal(false)}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>
            <Suspense fallback={<div>Loading...</div>}>
              <UserLevelStatusLazy />
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
};

export default GamingModal;
